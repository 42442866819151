/**
 * Main javascript file for Univ.
 * This file uses revealing module pattern
 */

gsap.registerPlugin(ScrollTrigger);

univ = function($) {

    var init = function() {
	    initialiseGlobal();
	    initialiseNorthOxford();
    };

    /*
     * global
     */
    var initialiseGlobal = function() {

    	$('#nav .menu_tab .sub-menu').before('<span class="dropdown"></span>');


    	//var _hero_container = $(this).parent().parent(); // goes up to the main container
             
    	
    	//if($('#hero .hero_img').attr('src') == '') { 
    	  //$('.hero_area').hide();
        //}
    	
    	if($('#hero:first-child .hero_img').attr('src') == '') { 
    		$('#hero:first-child').hide();
        }
    	//else {
    		//$('#hero:first-child').show();
        //}	
    	
    	
        if ($(window).width() < 959) {

    		$(function() {
    		    function abso() {
    		        $('.search_form').css({
    		            height: $(window).height()-60
            		});
    		    }
    		    $(window).resize(function() {
            		abso();         
    		    });
    		    abso();
    		});

    		$('.search_form_link').click(function(){
    			$('.menu_link').removeClass('menu_link_close');
    			$('#nav .menu').hide();			
    			$(this).toggleClass('search_form_link_close');
    			$('.search_form').slideToggle();
    		});	

    		$('.menu_link').click(function(){
    			$('.search_form_link').removeClass('search_form_link_close');
    			$('.search_form').hide();
    			$(this).toggleClass('menu_link_close');
    			$('#nav div .menu').slideToggle();
    		});	

    		$("#nav").each(function () {
    	        $(this).find(".menu_tab .dropdown").click(function() {
    				$(this).parent().parent().find(".menu_tab .dropdown").removeClass('dropdown_open').next().slideUp(300);
    				if ($(this).next().is(':hidden')) {
    					$(this).parent().parent().find(".menu_tab .dropdown").removeClass('dropdown_open').next().slideUp(300);
    					$(this).toggleClass('dropdown_open').next().slideDown(300);
    				}
        	    });
    	    });
        
       	}
    	else {
    	
    		$('.search_form_link').click(function(){
    			$('.search_form_link').toggleClass('search_form_link_close');
    			$('#nav .menu').slideToggle(100);
    			$('.search_form').slideToggle(100);
    		});
    		$('#nav').click(function(){
    			$('.search_form_link').addClass('search_form_link_close');
    			$('#nav .menu').slideUp(100);
    			$('.search_form').slideDown(100);
    		});
    		
    		$('#nav a').click(function(e) {
    			e.stopPropagation();
    		});
    		
    	}
        
        //do we have a carousel on the single book page to initialise?
        $related = $('.single-book .related_books');
        if ($related.length) {
        	$related.slick({
    	  		infinite: true,
    	  		mobileFirst: true,
    			dots: true,
    	  		slidesToShow:1,
    	  		slidesToScroll:1,
    			autoplay:false,
    			autoplaySpeed: 4000,
    			variableWidth:false,
    			responsive: [
    			    {
    			      breakpoint: 767,
    			      settings: {
    	        		slidesToShow: 2,
    			        slidesToScroll: 1,
    	        		infinite: true,
    			        dots: false,
    					variableWidth: false
    			      }
    			    },
    			    {
    			      breakpoint: 959,
    			      settings: {
    	        		slidesToShow: 3,
    			        slidesToScroll: 1,
    	        		infinite: true,
    			        dots: false,
    					variableWidth: false
    			      }
    			    }
    		  	]		
    		});	
        }
    	
    };

    /*
     * North Oxford page template
     */
    var initialiseNorthOxford = function() {
    	if ($('.page-template-page-north-oxford').length) {
				
	        gsap.defaults({
	          ease: "Power1.easeInOut",
	        });

		    //video player
	    	var $videos = $('.full_width_video, .zig_zag_video_left, .zig_zag_video_right');
	    	if ($videos.length) {
	    		$videos.each(function() {
	    			var $this = $(this),
						$button = $this.find('button'),
						$iframe = $this.find('iframe'),
						$wrapper = $this.find('.video'),
						$close = $this.find('.fa-times');
					$button.click(function() {
						console.log('caught button click');
						if ($iframe.attr('src')=='') {
							$iframe.attr('src',$button.data('video'));
							$wrapper.fadeIn();
						}
					});	  
					$close.click(function() {
						$iframe.attr('src', '');
						$wrapper.fadeOut();
					}); 
	    		});
	    	}
	    	
	    	//for accordion action on naming opportunity groups
	    	if ($('.naming_opportunities').length) {
	    		$('.naming_opportunities h3').click(function() {
	    			$(this).next('.items').slideToggle();
	    		});
	    	}

			var $wipes = $('.zig_zag_video_left, .zig_zag_video_right').find('.wipe');
			if ($wipes.length) {
			
				$wipes.each(function() {
					var wipe = this;
					gsap.to(wipe, {
					    scrollTrigger:{
					      trigger: wipe,
					      start: "top center",
					      end: "bottom center",
					      //scrub: true,
					      //markers: true,
					    },
					    width: 0,
					  })
				});  
			}
			
			//image zoom effects as we scroll 
			var $imageZooms = $('.scroll-zoom');
			if ($imageZooms.length) {
				$imageZooms.each(function() {
					var image = this;
					gsap.to(image, {
					    scrollTrigger:{
					      trigger: image,
					      start: "top center",
					      end: "bottom center",
					      //scrub: true,
					      //markers: true,
						  toggleActions: "play complete reverse reset"
					    },
					    css:{scale:1.1},
						duration: 0.2
					  })
				}); 
			}
			
			if ($('#mini-nav').length) {
				var $navLinks = $('#mini-nav a');
				$navLinks.click(function() {
					var $target = $($(this).attr('href'));
					if ($target.length) {
						$('html, body').animate({
						    scrollTop: $target.offset().top
						}, 500);
					}
				});
	
	            $(window).scroll(function() {
	
					
	                //Get current scroll position
	                var currentScrollPos = $(document).scrollTop();
	
	                //Iterate through all node
	                $navLinks.each(function () {
	                    var curLink = $(this),
	                    	refElem = $(curLink.attr('href'));
	                    //Compare the value of current position and the every section position in each scroll
	                    if (refElem.position().top <= currentScrollPos && refElem.position().top + refElem.height() > currentScrollPos) {
	                        //Remove class active in all nav
	                        $('#mini-nav a.active').removeClass("active");
	                        //Add class active
	                        curLink.addClass("active");
	                    } else {
	                        curLink.removeClass("active");
	                    }
	                });
	            }).scroll();
			}
    	}
    };

    
    return{init:init}//return items that are available outside

}(jQuery);


//document ready
jQuery(function() {
	univ.init();
});


